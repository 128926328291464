import React from 'react'
import Layout from '../components/layout/Layout'
import Button from '../components/styled-components/Button'
import { Link } from 'gatsby'
import SEO from '../components/SEO'
import { ColumnWrapper } from '../components/styled-components/Wrappers.styled'

const NotFoundPage = () => (
    <Layout headerMode="shop">
        <SEO title="Brak strony o podanym adresie" />
        <ColumnWrapper width="960px" height="450px" margin="auto">
            {' '}
            <h2>Taka strona nie istnieje :(</h2>
            <Button
                light
                as={Link}
                to="/"
                aria-label="Przejdź do strony głównej"
            >
                Wróć do strony głównej
            </Button>
        </ColumnWrapper>
    </Layout>
)

export default NotFoundPage
